footer {
    width: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    color: var(--secondary);
    background-color: var(--dark);
    padding: 2vh;
}

.cols {
    max-width: 100vw;
    display: flex;
}

@media screen and (max-width: 768px) {
    .cols {
        flex-direction: column;
    }
    .col {
        margin-bottom: 3vh;
    }
}

.col {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.col h3 {
    font-size: 1.6rem;
    margin-bottom: 1vh;
}

.col ul {
    list-style-type: none;
}

.col ul li a {
    color: var(--secondary);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
}

.col ul li a:hover {
    color: var(--selected);
    background-color: var(--secondary);
    border-radius: 0.2rem;
    transform: scale(1.1);
    transition: ease 80ms;
}

.icon {
    margin-right: 3px;
}

.footer-bottom {
    text-align: center;
    font-size: 1.35rem;
}