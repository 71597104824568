.drumbox-wrapper{
    border: solid 2px #B8A6D5;
    margin: 2rem auto 2rem auto;
    border-radius: 20px;
    display: table;
    padding: 2rem;
    color: #B8A6D5;
    font-weight: 500;
    letter-spacing: 10%;
    text-transform: uppercase;
}
.drumbox-row{
    display: flex;
    margin: 0 auto 0 auto;
    padding: 0 auto 0 auto;
}