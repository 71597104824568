body{
    background: linear-gradient(269.88deg, #D9D9D9 0.09%, #04040A 0.1%, #331D5D 99.89%);
}
select{
    font-size: 1rem;
    font-weight: bolder;
    border: 2px solid #B8A6D5;
    border-radius: 10px;
    padding: 1rem;
    background: transparent;
    margin: 2rem;
    color: #B8A6D5;
    text-transform: uppercase;
    letter-spacing: 5px;
}
button{
    font-size: 1rem;
    font-weight: bolder;
    border: 2px solid #B8A6D5;
    border-radius: 10px;
    padding: 1rem;
    background: transparent;
    margin: auto;
    color: #B8A6D5;
    text-transform: uppercase;
    letter-spacing: 5px;
    display: flex;
}
button:hover{
    transform: scale(1.2);
    transition: ease 300ms;
    background:#B8A6D5;
    color: var(--primary);
}