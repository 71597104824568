.drumname-wrapper{
    border: 2px solid #B8A6D5;
    border-radius: 20px;
    margin: 1rem;
    width:15rem;
    display: flex;
    padding: 1rem;
    
}
input{
    border: 0;
    border-radius: 50%;
    margin: 0;
    padding: 0;
}
.p{
    padding: 0;
    position: relative;
    margin: auto auto auto 0; 
}
