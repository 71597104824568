.account-management-wrapper {
    width: 450px;
    max-width: 100vw;

    min-height: 600px;
    max-height: 80vh;

    border-radius: 10px;
    margin: 20px auto;
    padding: 1vh 0;
    box-shadow: 0 0 1.5rem black;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    background-color: var(--secondary);
}

.initial-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.initial-message-container h2 {
    font-size: 2rem;
    margin-bottom: 0.75vh;
}

.initial-message-container h3 {
    color: var(--primary);
}

.initial-message-container h3:hover {
    color: var(--selected);
    cursor: pointer;
    text-decoration: underline;
}

.separator {
    width: 90%;
    height: 1px;
    background-color: var(--dark);
    margin: 1vh auto;
}

.account-management-wrapper form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.account-management-wrapper form label {
    font-size: 1.5rem;
    color: var(--dark);
}

.account-management-wrapper form input {
    width: 80%;
    text-align: center;
    font-size: 1rem;
    height: 30px;
}

.account-management-wrapper form input[type="submit"] {
    height: auto;
    background-color: var(--primary);
    color: var(--secondary);
    padding: 10px 0;
    font: inherit;
    font-size: 2rem;
    cursor: pointer;
    border: none;
    margin-top: auto;
}

.account-management-wrapper .sign-in-with-google {
    display: flex;
    justify-content: center;
}

.fieldWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.error {
    color: red;
}