nav {
    max-width: 100vw;
    background-color: var(--primary);
    position: sticky;
    top: 0;
    z-index: 10;
}

.links {
    display: flex;
    padding: 1vh 0;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    list-style-type: none;
    z-index: 2;
}

.links li:hover {
    transform: scale(1.1);
    transition: ease 300ms;
}

.link {
    color: var(--secondary);
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.5vh 0.4vw;
    transition: ease 200ms;
}

@media screen and (min-width: 476px) {
    .link:hover {
        background-color: var(--selected);
        border-radius: 0.5rem;
        transition: ease 200ms;
        transform: scale(2);
    }
}

.linkGroup {
    display: flex;
    width: 15vw;
    justify-content: space-between;
}

.hamburger {
    height: 100%;
    width: 30px;
}

.hambar-ul {
    display: none;
}

.line {
    height: 15%;
    width: 100%;
    background-color: var(--secondary);
}

.hidden {
    display: none;
}

@media screen and (max-width: 1200px) {
    .linkGroup {
        width: 20vw;
    }
}

@media screen and (max-width: 960px) {
    .linkGroup {
        width: 25vw;
    }
}

@media screen and (max-width: 700px) {
    .linkGroup {
        width: 30vw;
    }
}

@media screen and (max-width: 540px) {
    .linkGroup {
        width: 35vw;
    }
}

@media screen and (max-width: 475px) {
    nav {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }
    .hamburger {
        display: block;
        width: 28px;
        height: 5vh;
        margin-top: 5px;
        margin-right: 5px;
    }
    nav ul {
        display: none;
    }
    .line:nth-child(even) {
        background-color: var(--primary);
    }
    .hambar-ul {
        display: block;
        list-style-type: none;
    }
    .hambar-ul-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index: 1;
    }
    .hambar-ul li {
        margin: 5px 0;
        border-bottom: var(--secondary) 2px solid;
    }
    .hambar-ul li .link {
        font-size: 1.6rem;
    }
}