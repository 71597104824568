.about-wrapper {
    min-height: 110vh;
    background-image: url("../../assets/party.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--secondary);
    position: relative;
}

@keyframes aboutTitle {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.about-wrapper .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-wrapper .cover h3 {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    padding-top: 5vh;
    animation: aboutTitle 1000ms ease;
}

.about-wrapper .cover button {
    background-color: var(--primary);
    box-shadow: 0 0 2rem black;
    border: none;
    font: inherit;
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--secondary);
    width: 30vw;
    border-radius: 1rem;
    margin-top: 5vh;
    padding: 0.2rem 1rem;
    transition: 150ms ease;
}

.about-wrapper .cover button:hover {
    text-decoration: underline;
    background-color: var(--selected);
    transform: scale(1.2);
    transition: 150ms ease;
}