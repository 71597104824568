.profile-wrapper {
    height: 100vh;
    max-width: 100vw;
    display: flex;
}

.profile-wrapper .sidebar {
    width: 20vw;
    background-color: var(--dark);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--secondary);
}

.profile-wrapper .sidebar * {
    margin: 2vh 0;
}

.profile-wrapper .sidebar img {
    width: 12vw;
    height: 12vw;
    border-radius: 50%;
}

.profile-wrapper .profile-content {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.profile-wrapper .profile-content h2 {
    font-size: 3rem;
}

.profile-wrapper .profile-content .projects {
    display: flex;
    flex-wrap: wrap;
}

.profile-wrapper .profile-content .projects * {
    margin: 2vh 2vw;
}

.settings-button:hover {
    cursor: pointer;
    text-decoration: underline;
}