.settings-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
}

.settings-wrapper form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
}

.settings-wrapper .settingsForm {
    justify-content: center;
}

.settings-wrapper .settingsForm input {
    width: 100%;
    text-align: center;
    font: inherit;
    font-size: 1rem;
    margin: 0;
}

.settings-wrapper form input[type='submit'] {
    font: inherit;
    border: none;
    border-radius: 1rem;
    font-size: 1rem;
    padding: 1rem;
    background-color: var(--secondary);
    cursor: pointer;
}