.gs-wrapper {
    min-height: 100vh;
    background: linear-gradient(0deg, var(--dark) 0%, rgba(81,56,128,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--secondary);
}

.gs-wrapper h3 {
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
    padding-top: 5vh;
    animation: aboutTitle 1000ms ease;
}

.gs-wrapper button {
    background-color: var(--primary);
    box-shadow: 0 0 2rem black;
    border: none;
    font: inherit;
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--secondary);
    width: 30vw;
    border-radius: 1rem;
    margin-top: 5vh;
    padding: 0.2rem 1rem;
    transition: 150ms ease;
}

.gs-wrapper button:hover {
    text-decoration: underline;
    background-color: var(--selected);
    transform: scale(1.2);
    transition: 150ms ease;
}