.home {
    background-image: url("../../assets/maincover.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    position: relative;
    color: var(--secondary);
}

.home .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5vw;
}

.home .cover .ts-wrapper .title {
    font-size: 9rem;
    font-weight: bold;
}

.home .cover .ts-wrapper .subtitle {
    font-size: 3rem;
    font-weight: bold;
}