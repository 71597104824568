.square{
    width: 4.5vh;
    height: 4.5vh;
    border: 2px solid #B8A6D5;
    border-radius: 10px;
    background:linear-gradient(180deg, rgba(184, 166, 213, 0.65) 19.34%, rgba(184, 166, 213, 0) 89.65%);
    position: relative;
    margin: 2rem 0.5rem 2rem 0.5rem;
    cursor: pointer;
}
.square:active{
    width: 4.5vh;
    height: 4.5vh;
    border: 2px solid #B8A6D5;
    border-radius: 10px;
    background:black;
    position: relative;
    margin: 2rem 0.5rem 2rem 0.5rem;
    cursor: grab;
}
.square:hover{
    transform: scale(0.8);
    transition: ease 300ms;
    background:linear-gradient(180deg, rgba(184, 166, 213, 0.2) 19.34%, rgba(184, 166, 213, 0) 89.65%);
}