@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --primary: hsl(261, 39%, 36%);
    --selected: hsl(261, 44%, 54%);
    --secondary: hsl(0, 0%, 95%);
    --dark: hsl(0, 0%, 17%);
    font-family: 'Exo 2', sans-serif;
    font-size: calc(1vmin + 8px);
}

body {
    background: linear-gradient(0deg, var(--dark) 0%, rgba(81,56,128,1) 100%);
    /*background-image: url("../../assets/bg.jpg");*/
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}