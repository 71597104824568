.project-component {
    width: 300px;
    height: 150px;
    background-color: var(--secondary);
    border-radius: 2rem;
    box-shadow: 0 0 2rem black;
    transition: 100ms ease;
    overflow: hidden;
    cursor: pointer;
}
.project-component:hover {
    transform: scale(1.2);
    transition: 100ms ease;
}

.project-component h3 {
    font-size: 1.5rem;
}